import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateSchoolMutationVariables = GraphQLTypes.Exact<{
  school: GraphQLTypes.UpdateSchoolInput;
}>;

export type UpdateSchoolMutationResult = { __typename?: 'Mutation' } & {
  updateSchool: { __typename?: 'School' } & Pick<GraphQLTypes.School, 'address' | 'city' | 'id' | 'name' | 'state' | 'zip'> & {
      district: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'id' | 'name'>;
    };
};

export const UpdateSchoolDocument = /* #__PURE__ */ gql`
  mutation updateSchool($school: UpdateSchoolInput!) {
    updateSchool(school: $school) {
      address
      city
      district {
        id
        name
      }
      id
      name
      state
      zip
    }
  }
`;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      school: // value for 'school'
 *   },
 * });
 */
export function useUpdateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutationResult, UpdateSchoolMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateSchoolMutationResult, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
}
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
