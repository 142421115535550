import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ResourceFiltersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type ResourceFiltersQueryResult = { __typename?: 'Query' } & {
  filters: { __typename?: 'Filters' } & {
    series: Array<{ __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'id' | 'key' | 'value'>>;
    stages: Array<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'id' | 'key' | 'value'>>;
  };
};

export const ResourceFiltersDocument = /* #__PURE__ */ gql`
  query resourceFilters {
    filters {
      series {
        id
        key
        value
      }
      stages {
        id
        key
        value
      }
    }
  }
`;

/**
 * __useResourceFiltersQuery__
 *
 * To run a query within a React component, call `useResourceFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourceFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>(ResourceFiltersDocument, options);
}
export function useResourceFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>(ResourceFiltersDocument, options);
}
export function useResourceFiltersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<ResourceFiltersQueryResult, ResourceFiltersQueryVariables>(ResourceFiltersDocument, options);
}
export type ResourceFiltersQueryHookResult = ReturnType<typeof useResourceFiltersQuery>;
export type ResourceFiltersLazyQueryHookResult = ReturnType<typeof useResourceFiltersLazyQuery>;
export type ResourceFiltersSuspenseQueryHookResult = ReturnType<typeof useResourceFiltersSuspenseQuery>;
