import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateUserMutationVariables = GraphQLTypes.Exact<{
  user: GraphQLTypes.UpdateUserInput;
}>;

export type UpdateUserMutationResult = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'User' } & Pick<
    GraphQLTypes.User,
    'classroom' | 'firstName' | 'gradeLevel' | 'id' | 'lastName' | 'loginPath' | 'schoolBuilding' | 'termsAccepted'
  >;
};

export const UpdateUserDocument = /* #__PURE__ */ gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      classroom
      firstName
      gradeLevel
      id
      lastName
      loginPath
      schoolBuilding
      termsAccepted
    }
  }
`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutationResult, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateUserMutationResult, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
