import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateUserMutationVariables = GraphQLTypes.Exact<{
  user: GraphQLTypes.CreateUserInput;
}>;

export type CreateUserMutationResult = { __typename?: 'Mutation' } & {
  createUser: { __typename?: 'User' } & Pick<
    GraphQLTypes.User,
    'classroom' | 'email' | 'firstName' | 'gradeLevel' | 'id' | 'lastName' | 'loginPath' | 'schoolBuilding' | 'termsAccepted'
  >;
};

export const CreateUserDocument = /* #__PURE__ */ gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user) {
      classroom
      email
      firstName
      gradeLevel
      id
      lastName
      loginPath
      schoolBuilding
      termsAccepted
    }
  }
`;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutationResult, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateUserMutationResult, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
