import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ResendInvitationEmailMutationVariables = GraphQLTypes.Exact<{
  userId: GraphQLTypes.Scalars['String']['input'];
}>;

export type ResendInvitationEmailMutationResult = { __typename?: 'Mutation' } & Pick<
  GraphQLTypes.Mutation,
  'resendInvitationEmail'
>;

export const ResendInvitationEmailDocument = /* #__PURE__ */ gql`
  mutation resendInvitationEmail($userId: String!) {
    resendInvitationEmail(userId: $userId)
  }
`;

/**
 * __useResendInvitationEmailMutation__
 *
 * To run a mutation, you first call `useResendInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationEmailMutation, { data, loading, error }] = useResendInvitationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInvitationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendInvitationEmailMutationResult, ResendInvitationEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ResendInvitationEmailMutationResult, ResendInvitationEmailMutationVariables>(
    ResendInvitationEmailDocument,
    options
  );
}
export type ResendInvitationEmailMutationHookResult = ReturnType<typeof useResendInvitationEmailMutation>;
