import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type SchoolsQueryVariables = GraphQLTypes.Exact<{
  page?: GraphQLTypes.InputMaybe<GraphQLTypes.GetPage>;
  filters?: GraphQLTypes.InputMaybe<GraphQLTypes.SchoolFilters>;
}>;

export type SchoolsQueryResult = { __typename?: 'Query' } & {
  schools: { __typename?: 'PaginatedSchool' } & Pick<GraphQLTypes.PaginatedSchool, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'School' } & Pick<GraphQLTypes.School, 'active' | 'city' | 'id' | 'name' | 'state' | 'zip'> & {
              district: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'active' | 'id' | 'name'>;
            }
        >
      >;
    };
};

export const SchoolsDocument = /* #__PURE__ */ gql`
  query schools($page: GetPage, $filters: SchoolFilters) {
    schools(page: $page, filters: $filters) {
      nodes {
        active
        city
        district {
          active
          id
          name
        }
        id
        name
        state
        zip
      }
      totalCount
    }
  }
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQueryResult, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SchoolsQueryResult, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQueryResult, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SchoolsQueryResult, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SchoolsQueryResult, SchoolsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<SchoolsQueryResult, SchoolsQueryVariables>(SchoolsDocument, options);
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsSuspenseQueryHookResult = ReturnType<typeof useSchoolsSuspenseQuery>;
