import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UsersQueryVariables = GraphQLTypes.Exact<{
  page: GraphQLTypes.GetPage;
  filters: GraphQLTypes.UserFilters;
}>;

export type UsersQueryResult = { __typename?: 'Query' } & {
  users: { __typename?: 'PaginatedUser' } & Pick<GraphQLTypes.PaginatedUser, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'User' } & Pick<
            GraphQLTypes.User,
            | 'classroom'
            | 'email'
            | 'firstName'
            | 'gradeLevel'
            | 'id'
            | 'lastName'
            | 'loginPath'
            | 'schoolBuilding'
            | 'status'
            | 'termsAccepted'
          >
        >
      >;
    };
};

export const UsersDocument = /* #__PURE__ */ gql`
  query users($page: GetPage!, $filters: UserFilters!) {
    users(page: $page, filters: $filters) {
      nodes {
        classroom
        email
        firstName
        gradeLevel
        id
        lastName
        loginPath
        schoolBuilding
        status
        termsAccepted
      }
      totalCount
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQueryResult, UsersQueryVariables> &
    ({ variables: UsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersQueryResult, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQueryResult, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersQueryResult, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQueryResult, UsersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersQueryResult, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
