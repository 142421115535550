import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DistrictQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type DistrictQueryResult = { __typename?: 'Query' } & {
  district: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'active' | 'externalId' | 'id' | 'name'>;
};

export const DistrictDocument = /* #__PURE__ */ gql`
  query district($id: ID!) {
    district(id: $id) {
      active
      externalId
      id
      name
    }
  }
`;

/**
 * __useDistrictQuery__
 *
 * To run a query within a React component, call `useDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictQuery(
  baseOptions: Apollo.QueryHookOptions<DistrictQueryResult, DistrictQueryVariables> &
    ({ variables: DistrictQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<DistrictQueryResult, DistrictQueryVariables>(DistrictDocument, options);
}
export function useDistrictLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictQueryResult, DistrictQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<DistrictQueryResult, DistrictQueryVariables>(DistrictDocument, options);
}
export function useDistrictSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DistrictQueryResult, DistrictQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<DistrictQueryResult, DistrictQueryVariables>(DistrictDocument, options);
}
export type DistrictQueryHookResult = ReturnType<typeof useDistrictQuery>;
export type DistrictLazyQueryHookResult = ReturnType<typeof useDistrictLazyQuery>;
export type DistrictSuspenseQueryHookResult = ReturnType<typeof useDistrictSuspenseQuery>;
