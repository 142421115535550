import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type BookQueryResult = { __typename?: 'Query' } & {
  book: { __typename?: 'Book' } & Pick<
    GraphQLTypes.Book,
    'asset' | 'id' | 'includedInDemo' | 'textType' | 'title' | 'videoAsset'
  > & {
      alternate?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      bigBook?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      bookType?: GraphQLTypes.Maybe<{ __typename?: 'BookType' } & Pick<GraphQLTypes.BookType, 'key'>>;
      flashCards?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      investigations?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      language?: GraphQLTypes.Maybe<{ __typename?: 'Language' } & Pick<GraphQLTypes.Language, 'key'>>;
      lessonPlan?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      pairedText1?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id' | 'title'>>;
      pairedText2?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id' | 'title'>>;
      perspectives?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      programOverview?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      related?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      runningRecord?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      series: { __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'key' | 'value'>;
      stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'key' | 'value'>>;
      teacherResourceBook?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      teachingNote?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      teksLessonPlan?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      vocabulary?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
      vocabularySideB?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
    };
};

export const BookDocument = /* #__PURE__ */ gql`
  query book($id: ID!) {
    book(id: $id) {
      alternate {
        id
      }
      asset
      bigBook {
        id
      }
      bookType {
        key
      }
      flashCards {
        id
      }
      id
      includedInDemo
      investigations {
        id
      }
      language {
        key
      }
      lessonPlan {
        id
      }
      pairedText1 {
        id
        title
      }
      pairedText2 {
        id
        title
      }
      perspectives {
        id
      }
      programOverview {
        id
      }
      related {
        id
      }
      runningRecord {
        id
      }
      series {
        key
        value
      }
      stage {
        key
        value
      }
      teacherResourceBook {
        id
      }
      teachingNote {
        id
      }
      teksLessonPlan {
        id
      }
      textType
      title
      videoAsset
      vocabulary {
        id
      }
      vocabularySideB {
        id
      }
    }
  }
`;

/**
 * __useBookQuery__
 *
 * To run a query within a React component, call `useBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookQuery(
  baseOptions: Apollo.QueryHookOptions<BookQueryResult, BookQueryVariables> &
    ({ variables: BookQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookQueryResult, BookQueryVariables>(BookDocument, options);
}
export function useBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookQueryResult, BookQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookQueryResult, BookQueryVariables>(BookDocument, options);
}
export function useBookSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookQueryResult, BookQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<BookQueryResult, BookQueryVariables>(BookDocument, options);
}
export type BookQueryHookResult = ReturnType<typeof useBookQuery>;
export type BookLazyQueryHookResult = ReturnType<typeof useBookLazyQuery>;
export type BookSuspenseQueryHookResult = ReturnType<typeof useBookSuspenseQuery>;
