import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookFiltersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type BookFiltersQueryResult = { __typename?: 'Query' } & {
  filters: { __typename?: 'Filters' } & {
    bookTypes: Array<{ __typename?: 'BookType' } & Pick<GraphQLTypes.BookType, 'id' | 'key' | 'value'>>;
    curriculumConnections: Array<
      { __typename?: 'CurriculumConnection' } & Pick<GraphQLTypes.CurriculumConnection, 'id' | 'value'>
    >;
    languages: Array<{ __typename?: 'Language' } & Pick<GraphQLTypes.Language, 'id' | 'key' | 'value'>>;
    levels: Array<{ __typename?: 'Level' } & Pick<GraphQLTypes.Level, 'id' | 'key' | 'value'>>;
    series: Array<{ __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'id' | 'key' | 'value'>>;
    stages: Array<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'id' | 'key' | 'value'>>;
  };
};

export const BookFiltersDocument = /* #__PURE__ */ gql`
  query bookFilters {
    filters {
      bookTypes {
        id
        key
        value
      }
      curriculumConnections {
        id
        value
      }
      languages {
        id
        key
        value
      }
      levels {
        id
        key
        value
      }
      series {
        id
        key
        value
      }
      stages {
        id
        key
        value
      }
    }
  }
`;

/**
 * __useBookFiltersQuery__
 *
 * To run a query within a React component, call `useBookFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookFiltersQuery(baseOptions?: Apollo.QueryHookOptions<BookFiltersQueryResult, BookFiltersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookFiltersQueryResult, BookFiltersQueryVariables>(BookFiltersDocument, options);
}
export function useBookFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookFiltersQueryResult, BookFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookFiltersQueryResult, BookFiltersQueryVariables>(BookFiltersDocument, options);
}
export function useBookFiltersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookFiltersQueryResult, BookFiltersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<BookFiltersQueryResult, BookFiltersQueryVariables>(BookFiltersDocument, options);
}
export type BookFiltersQueryHookResult = ReturnType<typeof useBookFiltersQuery>;
export type BookFiltersLazyQueryHookResult = ReturnType<typeof useBookFiltersLazyQuery>;
export type BookFiltersSuspenseQueryHookResult = ReturnType<typeof useBookFiltersSuspenseQuery>;
